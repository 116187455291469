* {
	@apply box-border scrollbar-thin scrollbar-track-mauve3 scrollbar-thumb-mauve9 scrollbar-corner-mauve2 selection:bg-violet9 selection:text-violet1 dark:selection:text-violet12;
	--webkit-font-smoothing: antialiased;
	--webkit-transform: translateZ(0);
	text-rendering: optimizeLegibility;
}

html {
	@apply h-dvh w-dvw scroll-smooth;
}

body {
	@apply m-0 flex h-dvh w-dvw flex-col overflow-y-auto overflow-x-hidden bg-mauve1 text-mauve12;
}

.prose-tight {
	* {
		@apply m-0 min-h-[1.625rem] leading-relaxed !important;
	}
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	-webkit-text-fill-color: var(--mauve-12);
	-webkit-background-clip: text;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	@apply hidden;
}

img:-moz-loading {
	@apply invisible;
}

input,
textarea {
	font: inherit;
	letter-spacing: inherit;
	word-spacing: inherit;
}
